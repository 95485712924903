import i18next from "i18next"

import api from "../../api/api"
import { getSpecializationsConfig, logOutConfig } from "../../api/routes"
import store from "../../store"
import theme from "../../themes/default/theme"
import { clearMedicalExamsPoints } from "../medicalExamsPoints/medicalExamsPoints.slice"
import { clearPatientDocumentation } from "../patientDocumentation/patientDocumentation.slice"
import { clearUserData } from "../user/user.slice"
import { getFontFamilyByClinicId } from "../../utils/getFontFamilyByClinicId"
import { redirectToError500Page } from "../../utils/handleErrors"
import {isAdult} from "../../utils/isAdult"
import { RoutePath } from "../../routes/Routes.types"
import { SetState } from "../../types/SetState"
import { SESSION_STORAGE_KEY } from "../session/session.types"
import {
  BENEFIT_SYSTEM_NO_BOOKING_PERMISSION,
  ClinicSettingsState,
  ClinicStyles,
  HealthPreventionPrograms
} from "./clinicSettings.types"
import { Theme } from "@material-ui/core/styles"

export const getClinicThemeFromStyles = async (
  clinicStyle: ClinicSettingsState["clinicSettings"]["frontendSettings"]["style"],
  clinicId:string
): Promise<Theme> => {
  //explicitly checking if it isn't null because 0 is falsey
  const shouldUseDynamicButtonBorderRadius = clinicStyle?.buttonBorderRadius !== null && clinicStyle.dynamicStylesEnabled
  const buttonBorderRadius = shouldUseDynamicButtonBorderRadius ? clinicStyle?.buttonBorderRadius : theme.shape.buttonBorderRadius
  const dynamicFont = clinicStyle.dynamicStylesEnabled && clinicStyle.dynamicFontsEnabled && await getFontFamilyByClinicId(clinicId)
  const dynamicFontWithFallback = dynamicFont && `'${dynamicFont}',`+theme.typography.fontFamily

  return {
    ...theme,
    shape : {
      ...theme.shape,
      buttonBorderRadius: buttonBorderRadius,
    },
    overrides: {
      ...theme?.overrides,
      MuiCssBaseline: { // is flagged as depreciated by mistake - https://github.com/mui/material-ui/issues/29015
        ...theme?.overrides?.MuiCssBaseline,
        "@global":{
          ...theme?.overrides?.MuiCssBaseline?.["@global"],
          body:{
            ...theme?.overrides?.MuiCssBaseline?.["@global"]?.body,
            fontFamily: dynamicFontWithFallback || theme.typography.fontFamily
          }
        }
      },
      MuiButton: {
        ...theme?.overrides?.MuiButton,
        root:{
          ...theme?.overrides?.MuiButton?.root,
          borderRadius: buttonBorderRadius,
          fontFamily: dynamicFontWithFallback || theme.typography.fontFamily
        },
        containedPrimary: {
          color: theme.palette.common.white,
          "&:hover, &:focus": {
            backgroundColor: clinicStyle.hoverColor
          }
        },
        outlinedPrimary: {
          borderColor: clinicStyle.mainColor,
          "&:hover, &:focus": {
            backgroundColor: clinicStyle.mainColor,
            color: theme.palette.common.white,
          },
        },
        outlinedSecondary: {
          borderColor: clinicStyle.mainColor,
          color: clinicStyle.mainColor,
          "&:hover, &:focus": {
            backgroundColor: clinicStyle.mainColor,
            color: theme.palette.common.white,
            borderColor: clinicStyle.mainColor
          },
        },
      }
    },
    palette: {
      ...theme.palette,
      background: {
        ...theme.palette.background,
        appBar: clinicStyle.dynamicStylesEnabled ?
          clinicStyle.dashboardHeaderColor
          : theme.palette.background.appBar,
        default: clinicStyle.dynamicStylesEnabled ?
          clinicStyle.dashboardBackgroundColor
          : theme.palette.background.default,
        footerBar: clinicStyle.dynamicStylesEnabled ?
          clinicStyle.dashboardFooterColor
          : clinicStyle.mainColor,
      },
      text: {
        ...theme.palette.text,
        footerBar: clinicStyle.dynamicStylesEnabled ?
          clinicStyle.dashboardFooterTextColor
          : theme.palette.text.footerBar,
        detailColor: clinicStyle.dynamicStylesEnabled ?
          clinicStyle.textDetailColor
          : theme.palette.text.detailColor
      },
      primary: {
        ...theme.palette.primary,
        main: clinicStyle.mainColor,
        dark: clinicStyle.hoverColor,
        darker: clinicStyle.hoverColor,
      },
    },
    typography:{
      ...theme.typography,
      fontFamily: dynamicFontWithFallback || theme.typography.fontFamily,
      body1: {
        ...theme.typography.body1,
        fontFamily: dynamicFontWithFallback || theme.typography.fontFamily,
      },
      body2: {
        ...theme.typography.body2,
        fontFamily: dynamicFontWithFallback || theme.typography.fontFamily,
      },
      h1:{
        ...theme.typography.h1,
        fontFamily: dynamicFontWithFallback || theme.typography.fontFamily,
      },
      h2:{
        ...theme.typography.h2,
        fontFamily: dynamicFontWithFallback || theme.typography.fontFamily,
      },
      h3:{
        ...theme.typography.h3,
        fontFamily: dynamicFontWithFallback || theme.typography.fontFamily,
      },
      h4:{
        ...theme.typography.h4,
        fontFamily: dynamicFontWithFallback || theme.typography.fontFamily,
      },
      h5:{
        ...theme.typography.h5,
        fontFamily: dynamicFontWithFallback || theme.typography.fontFamily,
      },
      h6:{
        ...theme.typography.h6,
        fontFamily: dynamicFontWithFallback || theme.typography.fontFamily,
      },
      button:{
        ...theme.typography.button,
        fontFamily: dynamicFontWithFallback || theme.typography.fontFamily,
      },
      subtitle1:{
        ...theme.typography.subtitle1,
        fontFamily: dynamicFontWithFallback || theme.typography.fontFamily,
      },
      subtitle2:{
        ...theme.typography.subtitle2,
        fontFamily: dynamicFontWithFallback || theme.typography.fontFamily,
      }
    }
  }
}

export const generateClinicStyleConfig = (clinicStyles: ClinicStyles): string[] => {
  const colors = Object.entries(clinicStyles)
  const colorsTotal = colors.length
  const colorsConfig: string[] = []

  for (let colorNumber=0; colorNumber <= colorsTotal - 1; colorNumber++) {
    if (typeof colors?.[colorNumber]?.[1] !== "string") {
      continue
    }

    colorsConfig.push(colors?.[colorNumber]?.[1])
  }

  return colorsConfig
}

export const handleCheckCustomIntegrationPermission = async (setAppThemeIsSet: SetState<boolean>, setCustomIntegrationRedirect: SetState<boolean>) => {
  const clinicId = store.getState().clinic?.clinicSettings?.frontendSettings?.clinicId

  try {
    setCustomIntegrationRedirect(false)

    await api.request({
      ...getSpecializationsConfig
    })
    setAppThemeIsSet(true)
    setCustomIntegrationRedirect(false)
  } catch(error) {
    if (error?.response?.data?.errorType === BENEFIT_SYSTEM_NO_BOOKING_PERMISSION) {
      await api.request({
        ...logOutConfig(clinicId),
        data: {},
      })
      store.dispatch(clearUserData())
      localStorage.removeItem(SESSION_STORAGE_KEY)
      store.dispatch(clearUserData())
      store.dispatch(clearMedicalExamsPoints())
      store.dispatch(clearPatientDocumentation())

      window.location.href = `/${i18next.language}/login?customIntegrationLogout=1`
    } else {
      redirectToError500Page(error)
      setAppThemeIsSet(true)
    }
  }
}

export const checkFeatureAvailability = () => {
  const clinicSettings = store.getState().clinic.clinicSettings
  const birthDate = store.getState().user?.userData?.birth_date
  const patientIsAdult = birthDate ? isAdult(birthDate) : true
  const host = window.location.hostname

  return {
    exams: clinicSettings.buyExamButtonEnabled,
    stationaryVisits: clinicSettings.stationaryVisitPatient,
    remoteConsultation: clinicSettings.hasBookConsultationAbility,
    prescriptionForPozGlobal: clinicSettings.prescriptionsSettings?.modules.filter((item => item === "5")).length > 0,
    prescription: parseInt(clinicSettings.prescriptionsSettings?.modules?.[0]) === 1 && patientIsAdult === true, // modules array from backend, first item -> 1 = prescriptions enabled
    sickLeave: parseInt(clinicSettings.sickLeaveSettings?.modules?.[0]) === 1, // modules array from backend, first item -> 1 = sick leave enabled
    disableRefferals: clinicSettings.frontendSettings.disableReferralsInDashboard,
    enableBenefitSystemIntegration: clinicSettings.frontendSettings.enableBenefitSystemIntegration,
    enabledTelemediGo: clinicSettings.frontendSettings.enabledTelemediGo,
    enabledTelemediGoInEprescription: clinicSettings.frontendSettings.enabledTelemediGoInEprescription,
    enabledTelemediGoInSickLeave: clinicSettings.frontendSettings.enabledTelemediGoInSickLeave,
    enabledTelemediGoForInternist: clinicSettings.frontendSettings.enabledTelemediGoForInternist,
    voucherCodeToBuy: clinicSettings?.clinicVoucherSettings?.voucherToBuy,
    showPhoneNumberOnCompleteDataView: clinicSettings.frontendSettings.showPhoneNumberOnCompleteDataView,
    hideChangePassword: clinicSettings.frontendSettings.hideChangePassword,
    hideConsultationBooking: clinicSettings.frontendSettings.hideConsultationBooking,
    patientUploadReferralsEnabled: clinicSettings.frontendSettings.modules && clinicSettings.frontendSettings.modules.includes("patient_upload_referrals"),
    isUniqaApiIntegrationEnabled: clinicSettings.frontendSettings.modules && clinicSettings.frontendSettings.modules.includes("uniqa"),
    emailStationaryVisits: host === "uniqa.telemedi.com" || host === "localhost" || host === "staging-dashboard.tmdi04.com" ? true : false,
    dependentAccountsEnabled : clinicSettings.clinicAllianzSettings.onyxVerificationEnabled,
    enabledHealthPlanner: clinicSettings.frontendSettings.enableHealthPlannerFeature,
    enabledMentalHealth: clinicSettings.clinicHealthPreventionSettings?.programs.includes(HealthPreventionPrograms.PROGRAM_MENTAL_HEALTH),
    enabledNewPrescriptionConsbookWidget: clinicSettings.prescriptionsSettings?.modules.includes("6")
  }
}

export const isClinicNotFoundView = () => window.location.href.includes(RoutePath.CLINIC_NOT_FOUND)
